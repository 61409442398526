import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TypeOfWaste from '../components/TypeOfWaste';
import { selectType } from '~/WasteEntry/actions';
import { showAppMessage } from '~/AppMessage/actions';


const mapStateToProps = ({
  wasteEntry: { type, quantity, kind },
  userConfig: { submitDelay },
}) => ({
  kind,
  type,
  quantity,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectType,
  showAppMessage
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypeOfWaste);
