import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Container from '../components/Container';
import { selectContainer, resetQuantity } from '~/WasteEntry/actions';
import { showAppMessage } from '~/AppMessage/actions';


const mapStateToProps = ({
  wasteEntry: { container, profile,destination },
  userConfig: { submitDelay },
}) => ({
  destination,
  container,
  profile,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectContainer,
  resetQuantity,
  showAppMessage
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
