import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WhyTossed from '../components/WhyTossed';
import { selectWhyTossed } from '~/WasteEntry/actions';
import { showAppMessage } from '~/AppMessage/actions';


const mapStateToProps = ({ wasteEntry: { whyTossed,kind }, userConfig: { submitDelay } }) => ({
  whyTossed,
  kind,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectWhyTossed,
  showAppMessage
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhyTossed);
